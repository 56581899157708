/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */

import React, { useCallback, useEffect, useMemo, useState , useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Labelbox, Button } from "../../components";
import "./employee.scss";
import ValidationLibrary from "../../utils/validationfunction";
import Grid from "@material-ui/core/Grid";
import { EMPLOYEE_ACTION, MASTER_ACTION } from "../../redux/action";
import {
  Toaster,
  dateFormatter,
  encryptPassword,
  decryptPassword,
  dropdownNameWithEmail,
} from "../../utils/common";
import { header } from "./defaultState";

const Employee = () => {
  const defaultState = {
    first_name: {
      labelname: "First Name",
      value: "",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    last_name: {
      labelname: "Last Name",
      value: "",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    email: {
      labelname: "Email Address",
      value: "",
      validation: [
        { name: "required" },
        { name: "emailNew" },
        { name: "finloEmail" },
      ],
      error: null,
      errmsg: null,
      placeholder: "email@finlotax.com",
    },
    password: {
      labelname: "Password",
      password: true,
      value: "",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    phone: {
      labelname: "Phone",
      value: "",
      validation: [{ name: "mobile" }],
      error: null,
      errmsg: null,
    },
    role_id: {
      labelname: "Role",
      value: "",
      type: "select",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    address_1: {
      labelname: "Address 1",
      value: "",
      //validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    address_2: {
      labelname: "Address 2",
      value: "",
      // validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    country_id: {
      labelname: "Country",
      value: "",
      type: "select",
      //validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    state_id: {
      labelname: "State",
      type: "select",
      value: "",
      //validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    city_id: {
      labelname: "City",
      value: "",
      //validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    zipcode: {
      labelname: "Zip Code",
      value: "",
      //validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    doj: {
      labelname: "Date of joining",
      type: "datepicker",
      value: "",
      //validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    designation: {
      labelname: "Designation",
      value: "",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    branch_office: {
      labelname: "Branch Office",
      type: "select",
      value: "",
      dropdown: [
        { id: "Sunnyvale - USA", value: "Sunnyvale - USA" },
        { id: "Pleasanton - USA", value: "Pleasanton - USA" },
        { id: "Bangalore - India", value: "Bangalore - India" },
      ],
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    status: {
      labelname: "Status",
      type: "select",
      value: 1,
      dropdown: [
        { id: 0, value: "Inactive" },
        { id: 1, value: "Active" },
      ],
      error: null,
      errmsg: null,
    },
  };

  const [editMode, setEditMode] = useState({ mode: false, employee_id: 0 });
  const [fieldConfiguration, setFieldConfiguration] = useState(defaultState);
  const [resetEvent, setResetEvent] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState(""); 
  const customRef = useRef(null)
  const dispatch = useDispatch();

  /////************ API DATA PROCESS************////////////
  useEffect(() => {
    dispatch(EMPLOYEE_ACTION.getAllEmployees());
    dispatch(EMPLOYEE_ACTION.getActiveEmployees());
    dispatch(EMPLOYEE_ACTION.getActiveManagers());
  }, [dispatch]);

  const {
    employee: { activeEmployees, activeManagers },
    master,
  } = useSelector((state) => state);

  const employeeDropdown = useCallback(
    (employee_id) => {
      const returnData = [];
      activeEmployees.forEach((data) => {
        if (data.employee_id !== employee_id)
          returnData.push({
            id: data.employee_id,
            value: dropdownNameWithEmail(data),
          });
      });
      return returnData;
    },
    [activeEmployees]
  );

  const managerDropdown = useMemo(() => {
    return activeManagers.map((data) => ({
      id: data.employee_id,
      value: dropdownNameWithEmail(data),
    }));
  }, [activeManagers]);

  const newFields = {
    reassigned_id: {
      labelname: "Reassigned To",
      value: "",
      type: "select",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
    },
    manager_id: {
      labelname: "Reporting Manager",
      value: "",
      type: "select",
      validation: [{ name: "required" }],
      error: null,
      errmsg: null,
      dropdown: managerDropdown,
    },
  };

  /********* RESPONSE ***********/
  useEffect(() => {
    const delay = setTimeout(() => {
      setDebouncedSearch(searchQuery);
    }, 2000);

    return () => clearTimeout(delay);
  }, [searchQuery]);

  useEffect(() => {
    dispatch(EMPLOYEE_ACTION.getAllEmployees({ search: debouncedSearch }));
  }, [dispatch, debouncedSearch]);

  const { employee: { allEmployees } } = useSelector((state) => state);

  const tableData = useMemo(() => {
    return allEmployees.map((data) => ({
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      phone: data.phone,
      role: data.role,
      doj: dateFormatter(data.doj),
      status: data.status ? "Active" : "Inactive",
    }));
  }, [allEmployees]);

  const masterSetup = {
    state: "state_id",
    role: "role_id",
    country: "country_id",
  };

  //////////////////
  const masterDataDropdown = () => {
    const mapKeys = Object.keys(masterSetup);
    mapKeys.forEach((data) => {
      if (Array.isArray(masterSetup[data])) {
        masterSetup[data].forEach((sub_data) => {
          if (fieldConfiguration.hasOwnProperty(sub_data)) {
            setFieldConfiguration((prevState) => ({
              ...prevState,
              [sub_data]: {
                ...fieldConfiguration[sub_data],
                dropdown:
                  sub_data === "business_state_id"
                    ? master[sub_data]
                    : master[data],
              },
            }));
          }
        });
      } else {
        if (fieldConfiguration.hasOwnProperty(masterSetup[data])) {
          setFieldConfiguration((prevState) => ({
            ...prevState,
            [masterSetup[data]]: {
              ...fieldConfiguration[masterSetup[data]],
              dropdown: master[data],
            },
          }));
        }
      }
    });
  };

  useEffect(() => {
    masterDataDropdown();
  }, [master, resetEvent]);

  /**
   * @function checkValidation used for validation purpose of input fileds
   * @param {string} data input field data
   * @param {string} key input field name
   */
  function checkValidation(data, key) {
    if (key === "country_id") {
      dispatch(
        MASTER_ACTION.getMasterData({
          name: "state",
          name_type: key === "business_country_id" && "business_state_id",
          id: data,
        })
      );
    } else if (key === "role_id") {
      resetFields(data === 3, "manager_id", newFields.manager_id);
    } else if (key === "status" && editMode.mode) {
      const reassignedDropdown = employeeDropdown(editMode.employee_id);
      resetFields(!data, "reassigned_id", {
        ...newFields.reassigned_id,
        dropdown: reassignedDropdown,
      });
    }
    const errorcheck = ValidationLibrary.checkValidation(
      data,
      fieldConfiguration[key].validation
    );
    const dynObj = {
      ...fieldConfiguration[key],
      value: data,
      error: !errorcheck.state,
      errmsg: errorcheck.msg,
    };

    setFieldConfiguration((prevState) => ({
      ...prevState,
      [key]: dynObj,
    }));
  }

  function resetFields(createProp, key, fieldset) {
    if (createProp) fieldConfiguration[key] = fieldset;
    else if (fieldConfiguration[key]) delete fieldConfiguration[key];
  }

  const handleCancel = () => {
    setFieldConfiguration(defaultState);
    setResetEvent(!resetEvent);
    setEditMode({ mode: false, employee_id: 0 });
  };

  /**
   *
   */
  async function onSubmit() {
    const mainvalue = {};
    const targetkeys = Object.keys(fieldConfiguration);
    for (const i in targetkeys) {
      const errorcheck = ValidationLibrary.checkValidation(
        fieldConfiguration[targetkeys[i]].value,
        fieldConfiguration[targetkeys[i]].validation
      );
      fieldConfiguration[targetkeys[i]].error = !errorcheck.state;
      fieldConfiguration[targetkeys[i]].errmsg = errorcheck.msg;
      mainvalue[targetkeys[i]] = fieldConfiguration[targetkeys[i]].value;
    }
    const filtererr = targetkeys.filter(
      (obj) => fieldConfiguration[obj].error === true
    );
    if (filtererr.length > 0) {
    } else {
      const params = {};
      targetkeys.forEach(
        (data) => (params[data] = fieldConfiguration[data].value)
      );

      const response = await EMPLOYEE_ACTION.insertUpdateEmployee({
        params: { ...params, password: encryptPassword(params.password) },
        insertUpdateFlag: editMode.mode ? 1 : 0,
        employee_id: editMode.employee_id,
      });
      if (response.status === 200) {
        Toaster.success(
          `Employee ${!editMode.mode ? "created" : "updated"} successfully`
        );
      } else {
        Toaster.error(
          `Error occurred while ${
            !editMode.mode ? "creating" : "updating"
          } the employee`
        );
      }
      dispatch(EMPLOYEE_ACTION.getAllEmployees());
      dispatch(EMPLOYEE_ACTION.getActiveEmployees());
      dispatch(EMPLOYEE_ACTION.getActiveManagers());

      handleCancel();
    }
    setFieldConfiguration((prevState) => ({
      ...prevState,
    }));
  }

  const onAction = async (type, actionData, index) => {
    if (type === "edit") {
   // onclick on edit smooth scroll
   customRef.current?.scrollIntoView( { behavior: "smooth"})
      const selectedData = allEmployees[index];
      const actionFieldConfiguration = defaultState;
      setEditMode({ mode: true, employee_id: selectedData.employee_id });
      [
        ...Object.keys(actionFieldConfiguration),
        "manager_id",
        "reassigned_id",
      ].forEach((data) => {
        if (data === "password") {
          actionFieldConfiguration[data].value = decryptPassword(
            selectedData[data]
          );
        } else if (data === "email") {
          actionFieldConfiguration[data].value = selectedData[data];
          actionFieldConfiguration[data].disabled = true;
        } else if (data === "country_id") {
          actionFieldConfiguration[data].value = selectedData[data];
          dispatch(
            MASTER_ACTION.getMasterData({
              name: "state",
              name_type: data === "business_country_id" && "business_state",
              id: selectedData[data],
            })
          );
        } else if (
          (data === "manager_id" && selectedData.role_id === 3) ||
          (data === "reassigned_id" &&
            selectedData.reassigned_id &&
            !selectedData.status)
        ) {
          actionFieldConfiguration[data] = {
            ...newFields[data],
            value: selectedData[data],
            dropdown:
              data === "reassigned_id"
                ? employeeDropdown(selectedData.employee_id)
                : newFields[data].dropdown,
          };
        } else if (
          actionFieldConfiguration[data] &&
          selectedData.hasOwnProperty(data)
        ) {
          actionFieldConfiguration[data].value = selectedData[data];
        } else delete actionFieldConfiguration[data];
      });
      setFieldConfiguration(actionFieldConfiguration);
    } else {
      // await dispatch(InsertUpdateDeleteClients(fieldConfiguration, actionData.employee_id));
    }
  };
  return (
    <Grid container alignItems="center" spacing={2}  ref={customRef}>
      <Grid item xs={12}>
        <div className="TitlePane">
          {!editMode.mode ? "Create" : "Update"} Employee
        </div>
      </Grid>
      {Object.keys(fieldConfiguration).map((data, index) => {
        if (!editMode.mode && data === "status") {
          return <></>;
        }
        const isRequired = fieldConfiguration[data]?.validation?.some(
          (rule) => rule.name === "required"
        );
        return (
          <Grid item xs={12} md={3} sm={6} key={`data${index}`}>
            <Labelbox
              type={fieldConfiguration[data].type || "text"}
              labelname={
                <span>
                  {fieldConfiguration[data].labelname}{" "}
                  {isRequired && <span style={{ color: "red" }}>*</span>}
                </span>
              }
              password={fieldConfiguration[data]?.password}
              dropdown={
                fieldConfiguration[data]?.type === "select" &&
                fieldConfiguration[data]?.dropdown
                  ? fieldConfiguration[data]?.dropdown
                  : false
              }
              changeData={(e) => checkValidation(e, data)}
              disabled={fieldConfiguration[data].disabled}
              value={fieldConfiguration[data].value}
              error={fieldConfiguration[data].error}
              errmsg={fieldConfiguration[data].errmsg}
              placeholder={fieldConfiguration[data].placeholder}
            />
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <div className="hbox">
          <Button
            text={`${editMode.mode ? "Update" : "Submit"}`}
            large
            handleClick={onSubmit}
          />
          <Button text="Cancel" large handleClick={handleCancel} />
        </div>
      </Grid>
      <Grid item xs={12} style={{ display: "flex"}}>
        <div style={{ width: "40%" }}>
          <Labelbox
            type="text"
            labelname="Search Employees"
            placeholder="Search by name or email..."
            value={searchQuery}
            changeData={(e) => setSearchQuery(e)}
          />
        </div>
      </Grid>
      <Grid item xs={12} className="paddingTop">
        <Table
          // search
          title="Employees"
          header={header}
          data={tableData}
          actions={["edit"]}
          onActionClick={onAction}
          restrictActionIcon
        />
      </Grid>
    </Grid>
  );
};
export default Employee;
